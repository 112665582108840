import { useMemoize } from '@vueuse/core'
import { Upload } from 'tus-js-client'

import supabase, { supabaseUrl } from '@/supabase'
import { ExpiringCache } from '@/utils'
import Semaphore from '@/utils/semaphore'

export default abstract class ImagesApi {

    protected abstract bucketName: string
    private semaphore = new Semaphore(10)

    public async fetchImageUrl(imageId: string, transform?: TransformOptions): Promise<string> {
      return await this.cachedImageUrl([imageId, transform])
    }

    public async downloadImage(imageId: string): Promise<Blob> {
      const { data, error } = await supabase
        .storage
        .from(this.bucketName)
        .download(imageId)
      if (error) throw error
      return data
    }

    public async uploadImage(file: File | Blob, imageId = crypto.randomUUID().toString(), onProgress?: (progress: number) => void): Promise<string> {
      const { data, error } = await supabase.auth.getSession()
      if (error) throw error
      if (!data.session) throw new Error('No active session')

      return new Promise((resolve, reject) => {
        const upload = new Upload(file, {
          endpoint: `${supabaseUrl}/storage/v1/upload/resumable`,
          retryDelays: [0, 1000, 2000, 4000, 8000],
          headers: {
            authorization: `Bearer ${data.session.access_token}`,
            'x-upsert': 'true'
          },
          uploadDataDuringCreation: true,
          removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
          metadata: {
            bucketName: this.bucketName,
            objectName: imageId,
            contentType: file.type,
            cacheControl: '3600',
          },
          chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
          onError: reject,
          onProgress: (bytesUploaded, bytesTotal) => {
            if (!onProgress) return
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            onProgress(Number(percentage))
          },
          onSuccess: () => resolve(imageId),
        })

        // Check if there are any previous uploads to continue.
        return upload.findPreviousUploads().then((previousUploads) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }

          // Start the upload
          upload.start()
        })
      })
    }

    public async deleteImage(imageId: string): Promise<void> {
      const { error } = await supabase
        .storage
        .from(this.bucketName)
        .remove([imageId])
      if (error) throw error
    }

    private cachedImageUrl = useMemoize(async ([imageId, transform]: [string, TransformOptions?]): Promise<string> => {
      return this.semaphore.use(async () => {
        const { data, error } = await supabase
          .storage
          .from(this.bucketName)
          .createSignedUrl(imageId, 60 * 60 * 24, { transform: { width: transform?.size, height: transform?.size, ...transform } })
        if (error) throw error
        return data.signedUrl
      }, transform?.size)
    },
    {
      cache: new ExpiringCache(1000 * 60 * 5), // 5 minutes
    })
}
