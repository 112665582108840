<template>
  <CarvisInput
    v-bind="$attrs"
    :id="id"
    input="input"
    :model-value="modelValue"
    @blur="$emit('blur')"
    @change="$emit('change', $event)"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template
      v-for="(_, name) in slots"
      #[name]
    >
      <slot :name="name" />
    </template>
  </CarvisInput>
</template>

<script setup lang="ts" generic="T">
defineEmits(['update:modelValue', 'blur', 'change'])

defineProps<{
  id: string,
  modelValue: T
}>()

const slots = defineSlots()
</script>
